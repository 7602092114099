import * as React from "react";
import Layout from "../components/Layout"; 
import SEO from '../components/SEO'

const TermsOfUse = () => {
   
  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Terms Of Use"}
        description={"LocalTier Terms of Use"}
      /> 

      <section className="bg-white">
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 sm:py-12 lg:px-8 prose">
          <div className="py-4">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-display font-extrabold text-gray-900 mb-3">Terms Of Use</h1>
            
 <p className="text-gray-500 mb-6 leading-relaxed">

          Updated: December 2021
            </p>

 
          </div>
        </div>

      </section>





    </Layout>
  );
};

export default TermsOfUse;
